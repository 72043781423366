import { useEffect } from "react";
import en from "../localization/en";
import ar from "../localization/ar";

export const useLocalization = () => {
  const lang = "en";

  useEffect(() => {}, [lang]);

  const localization = {
    en,
    ar,
  };

  const getLocalizationText = (key) => {
    const currLocalization = localization[lang] || en;
    return currLocalization[key] || en[key] || key;
  };

  return getLocalizationText;
};
