import React, { lazy, Suspense } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ColorModeContext, useMode } from "./theme";
import {
  Box,
  CircularProgress,
  CssBaseline,
  Grid,
  ThemeProvider,
} from "@mui/material";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Topbar from "./Layout/Topbar";
import Sidebar from "./Layout/Sidebar";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { clearUserData } from "./Store/Slices/UserSlice";
import PrivateRoute from "routes/PrivateRoute";
import allPermissions from "routes/allPermissions";

const Dashboard = lazy(() => import("./pages/dashboard"));
const ResetPassword = lazy(() => import("./pages/Login/ResetPassword"));
const WorkOrder = lazy(() => import("./pages/printHouse/workOrder/WorkOrder"));
const CreateWorkOrder = lazy(() =>
  import("./pages/printHouse/workOrder/CreateWorkOrder")
);
const VerifyStatusPH = lazy(() =>
  import("./pages/printHouse/verifyStatus/VerifyStatusPH")
);
const Login = lazy(() => import("pages/Login/Login"));
const Aggregation = lazy(() =>
  import("pages/aggregationHouse/aggregation/Aggregation")
);
const AggregateInbound = lazy(() =>
  import("pages/aggregationHouse/aggregationInbound/AggregateInbound")
);
const AggregateOutbound = lazy(() =>
  import("pages/aggregationHouse/aggregationOutbound/AggregateOutbound")
);
const AggregateCreateShipOut = lazy(() =>
  import(
    "pages/aggregationHouse/aggregationOutbound/AggregateCreateOutboundOrder"
  )
);
const AggregateOutboundScan = lazy(() =>
  import("pages/aggregationHouse/aggregationOutbound/AggregateOutboundScan")
);
const AggregateByStack = lazy(() =>
  import("pages/aggregationHouse/aggreationByStack/AggreationByStack")
);
const VerifyStatusAGG = lazy(() =>
  import("./pages/aggregationHouse/verifyStatus/VerifyStatusAGG")
);
const GenuineProduct = lazy(() =>
  import("pages/genuineProduct/GenuineProduct")
);
const InventoryACIP = lazy(() =>
  import("pages/capHouse/acipInventory/InventoryACIP")
);
const InboundACIP = lazy(() =>
  import("pages/capHouse/acipInbound/InboundACIP")
);
const OutboundACIP = lazy(() =>
  import("pages/capHouse/acipOutbound/OutboundACIP")
);
const WorkOrdersACIP = lazy(() =>
  import("pages/capHouse/acipWorkOrders/WorkOrdersACIP")
);
const CreateOutboundOrderACIP = lazy(() =>
  import("pages/capHouse/acipOutbound/CreateOutboundOrderACIP")
);
const Configurations = lazy(() =>
  import("pages/counterfeit/configurations/Configurations")
);
const AllCAPs = lazy(() => import("pages/capHouse/caps/CAPs"));
const EditConfigurationApproval = lazy(() =>
  import("pages/counterfeit/configurations/EditConfigurationApproval")
);
const Notifications = lazy(() =>
  import("pages/counterfeit/notifications/Notifications")
);
const DashboardCounterfeit = lazy(() =>
  import("pages/counterfeit/dashbord/DashboardCounterfeit")
);
const ProductsStatistics = lazy(() =>
  import("pages/counterfeit/productStatistics/ProductsStatistics")
);
const AlarmSettings = lazy(() =>
  import("pages/counterfeit/alarmSettings/AlarmSettings")
);
const AlarmsNotifications = lazy(() =>
  import("pages/counterfeit/alarmsNotifications/AlarmsNotifications")
);
const AlarmReports = lazy(() =>
  import("pages/counterfeit/alarmReports/AlarmReports")
);
const CreateAlarmReport = lazy(() =>
  import("pages/counterfeit/alarmReports/CreateAlarmReport")
);
const AlarmReportDetails = lazy(() =>
  import("pages/counterfeit/alarmReports/AlarmReportDetails")
);
const AccessForbidden = lazy(() =>
  import("pages/accessForbidden/AccessForbidden")
);
const ViewAlarmDetails = lazy(() =>
  import("pages/counterfeit/alarmsNotifications/components/ViewAlarmDetails")
);
const WADs = lazy(() => import("pages/supplyChain/masterData/wads/WADs"));
const CAPVendors = lazy(() =>
  import("pages/supplyChain/masterData/capVendor/CAPVendors")
);
const CAPs = lazy(() => import("pages/supplyChain/masterData/caps/CAPs"));
const FinishedProducts = lazy(() =>
  import("pages/supplyChain/masterData/finishedProducts/FinishedProducts")
);
const PrintHouseMD = lazy(() =>
  import("pages/supplyChain/masterData/printHouse/PrintHouseMD")
);
const AggregationHouseMD = lazy(() =>
  import("pages/supplyChain/masterData/aggregationHouse/AggregationHouseMD")
);

const AllWADs = lazy(() => import("pages/aggregationHouse/wads/AllWADs"));

const LOBPHouse = lazy(() =>
  import("pages/supplyChain/masterData/lobpHouse/LOBPHouse")
);
const LinkingFacilities = lazy(() =>
  import("pages/supplyChain/masterData/linkingFacilities/LinkingFacilities")
);
const Disaggregation = lazy(() =>
  import("pages/aggregationHouse/disaggregation/Disaggregation")
);
const PalletDisaggregation = lazy(() =>
  import("pages/capHouse/palletDisaggregation/PalletDisaggregation")
);
const ProductVerification = lazy(() =>
  import("pages/supplyChain/productVerification/ProductVerification")
);
const DashboardSupplyChain = lazy(() =>
  import("pages/supplyChain/dashbord/DashboardSupplyChain")
);
const InventorySupplyChain = lazy(() =>
  import("pages/supplyChain/inventorySupplyChain/InventorySupplyChain")
);
const DashboardLoyalty = lazy(() =>
  import("pages/loyalty/dashboard/DashboardLoyalty")
);
const RepeatedScans = lazy(() =>
  import("pages/counterfeit/repeatedScans/RepeatedScans")
);
const StackAndCartonAggregation = lazy(() =>
  import("pages/aggregationHouse/aggreationByStack/StackAndCartonAggregation")
);
const PrinterSetup = lazy(() =>
  import("pages/PrinterAndDeviceConfiguration/PrinterSetup")
);
const Roles = lazy(() =>
  import("pages/supplyChain/accessManagement/roles/Roles")
);
const AddRole = lazy(() =>
  import("pages/supplyChain/accessManagement/roles/AddRole")
);
const EditRole = lazy(() =>
  import("pages/supplyChain/accessManagement/roles/EditRole")
);
const Users = lazy(() =>
  import("pages/supplyChain/accessManagement/users/Users")
);
const AddUser = lazy(() =>
  import("pages/supplyChain/accessManagement/users/AddUser")
);
const EditUser = lazy(() =>
  import("pages/supplyChain/accessManagement/users/EditUser")
);
const Machines = lazy(() =>
  import("pages/machineConfigurations/MachineConfigurations")
);
const AddMachine = lazy(() => import("pages/machineConfigurations/AddMachine"));
// Edit machine
// const EditMachine = lazy(() => import("pages/machineConfigurations/EditMachine"));

function App() {
  const userData = useSelector((state) => state.user.data);
  const dispatch = useDispatch();
  const [theme, colorMode] = useMode(userData?.ownerId || true);
  const navigate = useNavigate();
  const location = useLocation();
  function logout() {
    dispatch(clearUserData());
    navigate("/");
  }

  return (
    // ColorModeContext.Provider to be changed after adding redux
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="app" dir={"ltr"}>
            {location?.pathname
              .toUpperCase()
              .includes("/genuineProduct".toUpperCase()) ? (
              <Suspense fallback={<SpinnerFallback />}>
                <GenuineProduct navigate={navigate} />
              </Suspense>
            ) : userData?.token == null || undefined ? (
              <Suspense fallback={<SpinnerFallback />}>
                <Login navigate={navigate} />
              </Suspense>
            ) : (
              <>
                <Sidebar />
                <main className="content">
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    width="100%"
                    height="100%"
                    flexWrap="nowrap"
                  >
                    <Grid item width="100%">
                      <Topbar logout={logout} />
                    </Grid>
                    <Grid item xs width="100%">
                      <Box
                        id="Main"
                        className="p-3"
                        height="100%"
                        bgcolor="#fff"
                      >
                        <Suspense fallback={<SpinnerFallback />}>
                          <Routes>
                            <Route
                              path="/accessForbidden"
                              element={<AccessForbidden />}
                            />
                            <Route
                              exact
                              path="/"
                              element={<Dashboard navigate={navigate} />}
                            />
                            <Route
                              exact
                              path="/resetPassword"
                              element={
                                <PrivateRoute requiredPermissions={""}>
                                  <ResetPassword
                                    logout={logout}
                                    navigate={navigate}
                                  />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              exact
                              path="/printWorkOrder"
                              element={<WorkOrder navigate={navigate} />}
                            />
                            <Route
                              exact
                              path="/printWorkOrder/create"
                              element={<CreateWorkOrder navigate={navigate} />}
                            />
                            <Route
                              exact
                              path="/verifyStatus/PH"
                              element={
                                <PrivateRoute
                                  requiredPermissions={
                                    allPermissions.VerifyPrintHouseSerial
                                  }
                                >
                                  <VerifyStatusPH navigate={navigate} />
                                </PrivateRoute>
                              }
                            />

                            <Route
                              exact
                              path="/aggregate"
                              element={
                                <PrivateRoute
                                  requiredPermissions={
                                    allPermissions.AGG_Aggregate
                                  }
                                >
                                  <Aggregation navigate={navigate} />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              exact
                              path="/aggregateByStack"
                              element={
                                <PrivateRoute
                                  requiredPermissions={
                                    allPermissions.StackAggregation
                                  }
                                >
                                  <AggregateByStack navigate={navigate} />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              exact
                              path="/aggregate/wads"
                              element={
                                <PrivateRoute
                                  requiredPermissions={
                                    allPermissions.StackConfiguration
                                  }
                                >
                                  <AllWADs navigate={navigate} />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              exact
                              path="/stackAndCartonAggregation"
                              element={
                                <StackAndCartonAggregation
                                  navigate={navigate}
                                />
                              }
                            />

                            <Route
                              exact
                              path="/aggregateInbound"
                              element={<AggregateInbound navigate={navigate} />}
                            />
                            <Route
                              exact
                              path="/aggregateOutbound"
                              element={
                                <AggregateOutbound navigate={navigate} />
                              }
                            />
                            <Route
                              exact
                              path="/aggregateOutbound/:id/scan"
                              element={
                                <AggregateOutboundScan navigate={navigate} />
                              }
                            />
                            <Route
                              exact
                              path="/aggregate/disaggregation"
                              element={<Disaggregation navigate={navigate} />}
                            />

                            <Route
                              exact
                              path="/aggregateOutbound/create"
                              element={
                                <AggregateCreateShipOut navigate={navigate} />
                              }
                            />
                            <Route
                              exact
                              path="/verifyStatus/AGG"
                              element={
                                <PrivateRoute
                                  requiredPermissions={
                                    allPermissions.VerifyAggregationHouseSerial
                                  }
                                >
                                  <VerifyStatusAGG navigate={navigate} />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              exact
                              path="/acipInventory"
                              element={
                                <PrivateRoute
                                  requiredPermissions={
                                    allPermissions.INV_ViewReport
                                  }
                                >
                                  <InventoryACIP navigate={navigate} />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              exact
                              path="/acipInbound"
                              element={
                                <PrivateRoute
                                  requiredPermissions={allPermissions.INOView}
                                >
                                  <InboundACIP navigate={navigate} />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              exact
                              path="/acipWorkOrder"
                              element={
                                <PrivateRoute
                                  requiredPermissions={allPermissions.CWOView}
                                >
                                  <WorkOrdersACIP navigate={navigate} />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              exact
                              path="/acip/pallet-disaggregation"
                              element={
                                <PrivateRoute
                                  requiredPermissions={
                                    allPermissions.AGG_ACIPDisAggregate
                                  }
                                >
                                  <PalletDisaggregation navigate={navigate} />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              exact
                              path="/acipOutbound"
                              element={
                                <PrivateRoute
                                  requiredPermissions={
                                    allPermissions.OutOrderView
                                  }
                                >
                                  <OutboundACIP navigate={navigate} />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              exact
                              path="/acipOutbound/create"
                              element={
                                <CreateOutboundOrderACIP navigate={navigate} />
                              }
                            />
                            <Route
                              exact
                              path="/acip/allCaps"
                              element={
                                <PrivateRoute
                                  requiredPermissions={
                                    allPermissions.CAPConfiguration
                                  }
                                >
                                  <AllCAPs navigate={navigate} />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              exact
                              path="/counterfeit/dashboard"
                              element={
                                <DashboardCounterfeit navigate={navigate} />
                              }
                            />
                            <Route
                              exact
                              path="/counterfeit/configurations"
                              element={<Configurations navigate={navigate} />}
                            />
                            <Route
                              exact
                              path="/counterfeit/edit-configurations"
                              element={
                                <EditConfigurationApproval
                                  navigate={navigate}
                                />
                              }
                            />
                            <Route
                              exact
                              path="/counterfeit/notifications"
                              element={<Notifications navigate={navigate} />}
                            />
                            <Route
                              exact
                              path="/counterfeit/products-statistics"
                              element={
                                <ProductsStatistics navigate={navigate} />
                              }
                            />

                            <Route
                              exact
                              path="/counterfeit/alarm-settings"
                              element={
                                <PrivateRoute
                                  requiredPermissions={
                                    allPermissions.AlarmViewing
                                  }
                                >
                                  <AlarmSettings navigate={navigate} />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              exact
                              path="/counterfeit/:id/alarm-details"
                              element={<ViewAlarmDetails navigate={navigate} />}
                            />
                            <Route
                              exact
                              path="/counterfeit/alarm-notifications"
                              element={
                                <PrivateRoute
                                  requiredPermissions={
                                    allPermissions.AlarmNotificationViewing
                                  }
                                >
                                  <AlarmsNotifications navigate={navigate} />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              exact
                              path="/counterfeit/alarm-reports"
                              element={
                                <PrivateRoute
                                  requiredPermissions={
                                    allPermissions.ViewAllReports
                                  }
                                >
                                  <AlarmReports navigate={navigate} />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              exact
                              path="/counterfeit/alarm-reports/:id/create"
                              element={
                                <PrivateRoute
                                  requiredPermissions={
                                    allPermissions.CreateReport
                                  }
                                >
                                  <CreateAlarmReport navigate={navigate} />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              exact
                              path="/counterfeit/alarm-reports/:id/details"
                              element={
                                <PrivateRoute
                                  requiredPermissions={
                                    allPermissions.ViewReportDetails
                                  }
                                >
                                  <AlarmReportDetails navigate={navigate} />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              exact
                              path="/counterfeit/repeated-serials"
                              element={
                                <PrivateRoute
                                  requiredPermissions={
                                    allPermissions.RepeatedSerialsViewing
                                  }
                                >
                                  <RepeatedScans navigate={navigate} />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              exact
                              path="/supply-chain/dashboard"
                              element={
                                <DashboardSupplyChain navigate={navigate} />
                              }
                            />
                            <Route
                              exact
                              path="/master-data/wads"
                              element={
                                <PrivateRoute
                                  requiredPermissions={
                                    allPermissions.WADViewing
                                  }
                                >
                                  <WADs navigate={navigate} />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              exact
                              path="/supply-chain/inventoryStock"
                              element={
                                <InventorySupplyChain navigate={navigate} />
                              }
                            />
                            <Route
                              exact
                              path="/master-data/caps"
                              element={
                                <PrivateRoute
                                  requiredPermissions={
                                    allPermissions.CAPViewing
                                  }
                                >
                                  <CAPs navigate={navigate} />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              exact
                              path="/master-data/finished-products"
                              element={
                                <PrivateRoute
                                  requiredPermissions={
                                    allPermissions.EndProductViewing
                                  }
                                >
                                  <FinishedProducts navigate={navigate} />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              exact
                              path="/master-data/print-house"
                              element={
                                <PrivateRoute
                                  requiredPermissions={
                                    allPermissions.PrintHousesViewing
                                  }
                                >
                                  <PrintHouseMD navigate={navigate} />
                                </PrivateRoute>
                              }
                            />

                            <Route
                              exact
                              path="/master-data/aggregation-house"
                              element={
                                <PrivateRoute
                                  requiredPermissions={
                                    allPermissions.AggregationHouseViewing
                                  }
                                >
                                  <AggregationHouseMD navigate={navigate} />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              exact
                              path="/master-data/cap-vendors"
                              element={
                                <PrivateRoute
                                  requiredPermissions={
                                    allPermissions.CappingVendorsViewing
                                  }
                                >
                                  <CAPVendors navigate={navigate} />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              exact
                              path="/master-data/lobp"
                              element={
                                <PrivateRoute
                                  requiredPermissions={
                                    allPermissions.LOBPViewing
                                  }
                                >
                                  <LOBPHouse navigate={navigate} />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              exact
                              path="/master-data/linkingFacilities"
                              element={
                                <PrivateRoute
                                  requiredPermissions={
                                    allPermissions.PartnerViewing
                                  }
                                >
                                  <LinkingFacilities navigate={navigate} />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              exact
                              path="/supply-chain/verifyProduct"
                              element={
                                <PrivateRoute
                                  requiredPermissions={
                                    allPermissions.ProductVerification
                                  }
                                >
                                  <ProductVerification navigate={navigate} />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              exact
                              path="/access-management/roles"
                              element={
                                <PrivateRoute
                                  requiredPermissions={
                                    allPermissions.RoleViewing
                                  }
                                >
                                  <Roles navigate={navigate} />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              exact
                              path="/access-management/add-role"
                              element={
                                <PrivateRoute requiredPermissions={""}>
                                  <AddRole navigate={navigate} />
                                </PrivateRoute>
                              }
                            />

                            <Route
                              exact
                              path="/access-management/:id/role"
                              element={
                                <PrivateRoute requiredPermissions={""}>
                                  <EditRole navigate={navigate} />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              exact
                              path="/access-management/users"
                              element={
                                <PrivateRoute
                                  requiredPermissions={allPermissions.ViewUsers}
                                >
                                  <Users navigate={navigate} />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              exact
                              path="/access-management/add-user"
                              element={
                                <PrivateRoute
                                  requiredPermissions={
                                    allPermissions.CreateUser
                                  }
                                >
                                  <AddUser navigate={navigate} />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              exact
                              path="/access-management/:id/user"
                              element={
                                <PrivateRoute
                                  requiredPermissions={allPermissions.EditUser}
                                >
                                  <EditUser navigate={navigate} />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              exact
                              path="/loyalty/dashboard"
                              element={<DashboardLoyalty navigate={navigate} />}
                            />
                            <Route
                              exact
                              path="/printerSetup"
                              element={
                                <PrivateRoute
                                  requiredPermissions={
                                    allPermissions.PrintingConfiguration
                                  }
                                >
                                  <PrinterSetup />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              exact
                              path="/machineConfigurations"
                              element={
                                <PrivateRoute
                                  requiredPermissions={
                                    allPermissions.MachineViewing
                                  }
                                >
                                  <Machines navigate={navigate} />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              exact
                              path="/machineConfigurations/add-machine"
                              element={
                                <PrivateRoute
                                  requiredPermissions={
                                    allPermissions.MachineCreation
                                  }
                                >
                                  <AddMachine navigate={navigate} />
                                </PrivateRoute>
                              }
                            />
                            {/* Edit machine */}
                            {/* <Route
                              exact
                              path="/machineConfigurations/:id/machine"
                              element={
                                <PrivateRoute
                                  requiredPermissions={}
                                >
                                  <EditMachine navigate={navigate} />
                                </PrivateRoute>
                              }
                            /> */}
                          </Routes>
                        </Suspense>
                      </Box>
                    </Grid>
                  </Grid>
                </main>
              </>
            )}
          </div>
        </LocalizationProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;

const SpinnerFallback = () => (
  <Box
    width="100%"
    height="100%"
    display="flex"
    justifyContent="center"
    alignItems="center"
  >
    <CircularProgress color="yellowShell" size="3rem" disableShrink />
  </Box>
);
