const aggregateParams = (queryParams) => {
  const { pagination, sorting, columnFilters } = queryParams;
  const params = [];

  // Checking Pagination object to add
  if (pagination) {
    params.push(`pageSize=${pagination?.pageSize}`);
    params.push(`pageNumber=${pagination?.pageIndex + 1}`);
  }

  // Checking Sorting object to add
  if (sorting && sorting?.length > 0) {
    sorting.forEach((sort) => {
      if (sort?.id !== "") {
        params.push(`sortBy=${sort?.id}`);
        params.push(`sortType=${sort?.desc ? 2 : 1}`);
      }
    });
  }

  // Checking Filtering object to add
  if (columnFilters && columnFilters?.length > 0) {
    const filterParams = columnFilters
      .map((filter) => {
        if (filter?.value !== "") {
          if (Array.isArray(filter?.value)) {
            if (filter?.value[0] || filter?.value[1]) {
              return `FromDate=${filter.value[0] ?? ""}&ToDate=${
                filter.value[1] ?? ""
              }`;
            }
          } else {
            return `${filter?.id}=${filter?.value}`;
          }
        }
        return null;
      })
      .filter((param) => param !== null); // Filter out null values
    params.push(...filterParams);
  }

  return params?.length > 0 ? `${params.join("&")}` : "";
};

export default aggregateParams;
