import Cookies from "js-cookie";
import languages from "./localization/Languages";
import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";

const currLangCode = Cookies.get("i18next") || "en";
const currLang = languages.find((l) => l.code === currLangCode);
const direction = currLang.dir || "ltr";

// color design themeMode export as tokens colors
export const themeMode = (mode) => ({
  ...(mode === "dark"
    ? {
        grey: {
          100: "#e0e0e0",
          200: "#c2c2c2",
          300: "#a3a3a3",
          400: "#858585",
          500: "#666666",
          600: "#525252",
          700: "#3d3d3d",
          800: "#292929",
          900: "#141414",
        },
        primary: {
          100: "#d0d1d5",
          200: "#a1a4ab",
          300: "#727681",
          400: "#1F2A40",
          500: "#141b2d",
          600: "#101624",
          700: "#0c101b",
          800: "#080b12",
          900: "#040509",
        },
        greenAccent: {
          100: "#dbf5ee",
          200: "#b7ebde",
          300: "#94e2cd",
          400: "#70d8bd",
          500: "#4cceac",
          600: "#3da58a",
          700: "#2e7c67",
          800: "#1e5245",
          900: "#0f2922",
        },
        redAccent: {
          100: "#f8dcdb",
          200: "#f1b9b7",
          300: "#e99592",
          400: "#e2726e",
          500: "#db4f4a",
          600: "#af3f3b",
          700: "#832f2c",
          800: "#58201e",
          900: "#2c100f",
        },
        blueAccent: {
          100: "#e1e2fe",
          200: "#c3c6fd",
          300: "#a4a9fc",
          400: "#868dfb",
          500: "#6870fa",
          600: "#535ac8",
          700: "#3e4396",
          800: "#2a2d64",
          900: "#151632",
        },
        orangeAccent: {
          100: "#e65100",
          200: "#ef6c00",
          300: "#f57c00",
          400: "#fb8c00",
          500: "#ff9800",
          600: "#ffa726",
          700: "#ffb74d",
          800: "#ffcc80",
          900: "#ffe0b2",
        },
      }
    : {
        grey: {
          100: "#141414",
          200: "#292929",
          300: "#3d3d3d",
          400: "#525252",
          500: "#666666",
          600: "#858585",
          700: "#a3a3a3",
          800: "#c2c2c2",
          900: "#e0e0e0",
        },
        primary: {
          100: "#040509",
          200: "#080b12",
          300: "#0c101b",
          400: "#F3F6F9", // manually changed background side and top bar
          500: "#141b2d",
          600: "#1F2A40",
          700: "#727681",
          800: "#a1a4ab",
          900: "#d0d1d5",
        },
        greenAccent: {
          100: "#0f2922",
          200: "#1e5245",
          300: "#2e7c67",
          400: "#3da58a",
          500: "#4cceac",
          600: "#70d8bd",
          700: "#94e2cd",
          800: "#b7ebde",
          900: "#dbf5ee",
        },
        redAccent: {
          100: "#2c100f",
          200: "#58201e",
          300: "#832f2c",
          400: "#af3f3b",
          500: "#db4f4a",
          600: "#e2726e",
          700: "#e99592",
          800: "#f1b9b7",
          900: "#f8dcdb",
        },
        blueAccent: {
          100: "#151632",
          200: "#2a2d64",
          300: "#3e4396",
          400: "#535ac8",
          500: "#6870fa",
          600: "#868dfb",
          700: "#a4a9fc",
          800: "#c3c6fd",
          900: "#e1e2fe",
        },
        yellowAccent: {
          100: "#4c4000",
          200: "#7f6b00",
          300: "#b29500",
          400: "#ccab00",
          500: "#FFD600",
          600: "#ffde32",
          700: "#ffe24c",
          800: "#ffea7f",
          900: "#fff2b2",
        },
        orangeAccent: {
          100: "#ffe0b2",
          200: "#ffcc80",
          300: "#ffb74d",
          400: "#ffa726",
          500: "#ff9800",
          600: "#fb8c00",
          700: "#f57c00",
          800: "#ef6c00",
          900: "#e65100",
        },
      }),
});

// mui theme settings
export const themeSettings = (mode, ownerId) => {
  const colors = themeMode(mode);

  return {
    direction: direction,
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            "&.Mui-disabled": {
              backgroundColor: colors.grey[900],
            },
          },
        },
      },
      // remove margins from all inputs
      MuiFormControl: {
        styleOverrides: {
          root: {
            marginTop: "0.25rem",
            marginBottom: 0,
          },
        },
      },
    },

    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            // palette values for dark mode
            primary: {
              main: colors.primary[500],
            },
            secondary: {
              main: colors.greenAccent[500],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: colors.primary[500],
            },
          }
        : {
            // palette values for light mode
            primary: {
              main: colors.primary[100],
            },
            secondary: {
              main: colors.greenAccent[500],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
              contrastText: colors.grey[500],
            },
            background: {
              default: "#fafcff", // <main/> background
              light: "#eef1f3",
            },
            text: {
              disabled: colors.grey[600],
            },

            yellowShell:
              ownerId === 1
                ? {
                    dark: colors.yellowAccent[400],
                    main: colors.yellowAccent[500],
                    light: colors.yellowAccent[600],
                    contrastText: colors.primary[100],
                  }
                : {
                    dark: "#570021",
                    main: "#810030",
                    light: "#B1607F",
                    contrastText: "#EFE8EA",
                  },
            redShell:
              ownerId === 1
                ? {
                    dark: colors.redAccent[400],
                    main: colors.redAccent[500],
                    light: colors.redAccent[600],
                    contrastText: colors.redAccent[900],
                  }
                : {
                    dark: colors.grey[300],
                    main: colors.grey[500],
                    light: colors.grey[600],
                    contrastText: colors.grey[900],
                  },
            start: {
              main: "#eef1f3",
              contrastText: "#ff9800",
            },
            create: {
              main: "#eef1f3",
              contrastText: "#8b6ca2",
            },
            cancel: {
              main: "#eef1f3",
              contrastText: "#ef5350",
            },
            approve: {
              main: "#eef1f3",
              contrastText: "#00a7ff",
            },
            complete: {
              main: "#eef1f3",
              contrastText: "#27b85a",
            },
            warning: {
              dark: colors.orangeAccent[400],
              main: colors.orangeAccent[500],
              light: colors.orangeAccent[600],
            },
          }),
    },
    typography: {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Montserrat", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Montserrat", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Montserrat", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Montserrat", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Montserrat", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Montserrat", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
  };
};

// context for color mode change to redux later
export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = (ownerId) => {
  const [mode, setMode] = useState("light");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === "light" ? "dark" : "light")),
    }),
    []
  );

  const theme = useMemo(
    () => createTheme(themeSettings(mode, ownerId)),
    [mode, ownerId]
  );
  return [theme, colorMode];
};
