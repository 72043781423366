import React, { useEffect, useState } from "react";
import { Avatar, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Divider from "@mui/material/Divider";
// import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import LogoutIcon from "@mui/icons-material/Logout";
import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";
import LockResetRoundedIcon from "@mui/icons-material/LockResetRounded";
import BaseURLDialog from "./BaseURLDialog";

const ProfileBarOnTapBar = (props) => {
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const [baseURL, setBaseURL] = useState(false);
  const [initials, setInitials] = useState("");
  const userData = useSelector((state) => state.user.data);
  const profileOpen = Boolean(profileAnchorEl);

  useEffect(() => {
    let isCancelled = false;
    const words = userData?.userName?.split(/[ _\W]/).filter(Boolean);
    const nameInitials = words.map((word) => word.charAt(0));
    if (!isCancelled) {
      if (nameInitials?.length === 1) {
        setInitials(userData?.userName?.substring(0, 2).toUpperCase());
      } else if (nameInitials?.length > 1) {
        setInitials(nameInitials?.join("").slice(0, 2).toUpperCase());
      } else {
        setInitials("G");
      }
    }

    return () => {
      isCancelled = true;
    };
  }, [userData?.userName]);

  const handleProfileClick = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };
  const handleProfileClose = () => {
    setProfileAnchorEl(null);
  };
  const handleSelectProfile = (lang) => {
    handleProfileClose();
    props.logout();
  };
  const handleResetPassword = () => {
    handleProfileClose();
    props.navigate("/resetPassword");
  };
  const handleChangeBaseURL = () => {
    handleProfileClose();
    setBaseURL(true);
  };

  const generateAvatarColor = (userInitials) => {
    const colors = [
      "#f44336",
      "#e91e63",
      "#9c27b0",
      "#673ab7",
      "#3f51b5",
      "#2196f3",
      "#03a9f4",
      "#00bcd4",
      "#009688",
      "#4caf50",
      "#8bc34a",
      "#b6bd6c",
      "#a93997",
      "#ffc107",
      "#ff9800",
      "#ff5722",
      "#795548",
      "#9e9e9e",
      "#607d8b",
      "#8e24aa",
    ];
    const charCodeSum = userInitials
      .split("")
      .reduce((acc, char) => acc + char.charCodeAt(0), 0);
    const colorIndex = charCodeSum % colors.length;
    return colors[colorIndex];
  };

  return (
    <div>
      <div
        className="TopbarProfilePhotoDiv"
        onClick={handleProfileClick}
        style={{ cursor: "pointer" }}
      >
        {initials && (
          <Avatar
            variant="rounded"
            sx={{ backgroundColor: generateAvatarColor(initials) }}
          >
            {initials}
          </Avatar>
        )}
        <div className="TopbarProfileNameDiv">
          <Typography variant="span" className="TopbarProfileName">
            {userData?.userName}
          </Typography>
          <Typography variant="span" className="TopbarProfileRole">
            {userData?.facilityName}
          </Typography>
        </div>
        <ArrowDropDownIcon style={{ fontSize: 30 }} />
      </div>
      <Menu
        id="basic-menu"
        anchorEl={profileAnchorEl}
        open={profileOpen}
        onClose={handleProfileClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {process.env.REACT_APP_ENV === "dev" && (
          <MenuItem
            style={{
              width: "220px",
              display: "flex",
              justifyContent: "space-around",
            }}
            onClick={handleChangeBaseURL}
          >
            <Typography color="success.main">
              Base URL <DeveloperModeIcon />
            </Typography>
          </MenuItem>
        )}
        {process.env.REACT_APP_ENV === "dev" && <Divider />}
        <Divider />
        {/* <MenuItem
          style={{
            width: "220px",
            display: "flex",
            justifyContent: "space-around",
          }}
          onClick={() => handleSelectProfile("option")}
        >
          <Typography className="ProfileDropDownProfile">
            Profile
            <PermIdentityIcon />
          </Typography>
        </MenuItem>
        <Divider /> */}
        <MenuItem
          style={{
            width: "220px",
            display: "flex",
            justifyContent: "space-around",
          }}
          onClick={handleResetPassword}
        >
          <Typography color="success.main">
            Reset Password <LockResetRoundedIcon />
          </Typography>
        </MenuItem>
        <Divider />
        <MenuItem
          style={{
            width: "220px",
            display: "flex",
            justifyContent: "space-around",
          }}
          onClick={() => handleSelectProfile("option")}
        >
          <Typography className="LogoutDropDownProfile">
            Logout <LogoutIcon />
          </Typography>
        </MenuItem>
      </Menu>
      {baseURL && (
        <BaseURLDialog open={baseURL} handleClose={() => setBaseURL(false)} />
      )}
    </div>
  );
};

export default ProfileBarOnTapBar;
