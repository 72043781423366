import React from "react";
import { useState } from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarContent,
  SidebarFooter,
} from "react-pro-sidebar";
import usePermission from "Hooks/usePermission";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import allPermissions from "routes/allPermissions";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { themeMode } from "../theme";
import { useLocalization } from "../Hooks/useLocalization";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
// import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import WaterDamageOutlinedIcon from "@mui/icons-material/WaterDamageOutlined";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import AddToQueueOutlinedIcon from "@mui/icons-material/AddToQueueOutlined";
import RemoveFromQueueOutlinedIcon from "@mui/icons-material/RemoveFromQueueOutlined";
// import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
// import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import UnarchiveOutlinedIcon from "@mui/icons-material/UnarchiveOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import Edit from "@mui/icons-material/Edit";
import DoorbellOutlinedIcon from "@mui/icons-material/DoorbellOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import DescriptionSharpIcon from "@mui/icons-material/DescriptionSharp";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
// import BlurCircularOutlinedIcon from "@mui/icons-material/BlurCircularOutlined";
import PlaylistAddCircleOutlinedIcon from "@mui/icons-material/PlaylistAddCircleOutlined";
import HorizontalSplitOutlinedIcon from "@mui/icons-material/HorizontalSplitOutlined";
import OilBarrelOutlinedIcon from "@mui/icons-material/OilBarrelOutlined";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import WarehouseOutlinedIcon from "@mui/icons-material/WarehouseOutlined";
import ChairAltOutlinedIcon from "@mui/icons-material/ChairAltOutlined";
import DynamicFeedOutlinedIcon from "@mui/icons-material/DynamicFeedOutlined";
import LockPersonOutlinedIcon from "@mui/icons-material/LockPersonOutlined";
import RoomPreferencesOutlinedIcon from "@mui/icons-material/RoomPreferencesOutlined";
import PermDataSettingIcon from "@mui/icons-material/PermDataSetting";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import HolidayVillageOutlinedIcon from "@mui/icons-material/HolidayVillageOutlined";

const Item = ({
  title,
  to,
  icon,
  setSelected,
  isMenuCollapsed,
  selected,
  permissions,
  checkAnyValidPermission = false, // if any of the permissions valid then show item
}) => {
  let location = useLocation();
  const theme = useTheme();
  const colors = themeMode(theme.palette.mode);
  const hasPermission = usePermission();
  // Check if any of the permissions is valid
  let shouldRender = false;
  if (Array.isArray(permissions)) {
    // Check if any of the permissions are valid
    const anyPermissionValid = permissions?.some((permission) =>
      hasPermission(permission)
    );
    // Check if all permissions are valid
    const allPermissionsValid = permissions?.every((permission) =>
      hasPermission(permission)
    );

    if (checkAnyValidPermission) {
      shouldRender = anyPermissionValid;
    } else {
      shouldRender = allPermissionsValid;
    }
  } else {
    shouldRender = hasPermission(permissions);
  }

  return (
    shouldRender && (
      <MenuItem
        active={
          selected === location.pathname
            ? to === selected
            : to === location.pathname
        }
        style={{
          color: colors.grey[100],
        }}
        onClick={() => setSelected(to)}
        icon={icon}
      >
        {!isMenuCollapsed ? (
          <Typography fontSize="0.8rem">{title}</Typography>
        ) : (
          ""
        )}
        <Link to={to} />
      </MenuItem>
    )
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = themeMode(theme.palette.mode);
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(false);
  const [selected, setSelected] = useState("");
  const loc = useLocalization();
  const facilityTypeId = useSelector(
    (state) => state.user?.data?.facilityTypeId
  );
  // console.log(
  //   "Permissions",
  //   useSelector((state) => state.user?.data?.permissionIds)
  // );
  let location = useLocation();
  const hasPermission = usePermission();
  return (
    <>
      <Box
        sx={{
          position: "sticky !important",
          top: 0,
          "& .pro-menu-item": {
            background: "transparent !important",
          },
          "& .pro-sidebar-inner": {
            background: `${colors.primary[400]} !important`,
          },
          "& .pro-icon-wrapper": {
            backgroundColor: "transparent !important",
          },
          "& .pro-inner-item": {
            padding: "0.25rem 1rem  !important",
          },
          "& .pro-inner-item:hover": {
            // TODO inset it from theme
            color: "#FF151F !important",
            transition: "none !important",
          },
          "& .pro-menu-item.active": {
            color: "#FF151F !important",
            // TODO inset it from theme
            backgroundColor: "#F8EBEB !important",
          },
          "& .pro-menu-item.pro-sub-menu": {
            color: "#141414 !important",
          },
          "& .pro-menu-item.pro-sub-menu.open": {
            // color: "#FF151F !important",
            fontWeight: "600 !important",
          },

          "& .pro-menu": {
            height: "inherit",
            padding: 0,
          },
          "& .pro-sidebar-layout ul": {
            display: "flex",
            height: "inherit",
            flexDirection: "column",
            justifyContent: "space-between",
          },
        }}
      >
        <ProSidebar collapsed={isMenuCollapsed} width={260}>
          <Menu iconShape="square">
            {/* LOGO AND MENU ICON */}

            <MenuItem
              onClick={() => setIsMenuCollapsed(!isMenuCollapsed)}
              icon={
                isMenuCollapsed ? (
                  <img
                    alt="expandIcon"
                    src={`../../assets/images/expandMenu.png`}
                    style={{ cursor: "pointer" }}
                  />
                ) : undefined
              }
              style={{
                margin: "15px 0 ",
                height: "62px",
              }}
            >
              {!isMenuCollapsed && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  m="auto"
                >
                  <img
                    src={`../../assets/svg/Origin_Primary.svg`}
                    width="70%"
                    alt="Origin"
                    style={{ padding: "0.5rem" }}
                  />
                  <IconButton
                    disableFocusRipple={true}
                    onClick={() => setIsMenuCollapsed(!isMenuCollapsed)}
                    sx={{ padding: "0.2rem" }}
                    size="large"
                    edge="start"
                  >
                    <img
                      src={`../../assets/images/expandMenu.png`}
                      alt="expandIcon"
                      style={{ cursor: "pointer" }}
                    />
                  </IconButton>
                </Box>
              )}
            </MenuItem>

            <SidebarContent>
              {facilityTypeId !== 5 &&
                facilityTypeId !== 6 &&
                facilityTypeId !== 7 && (
                  <Item
                    title={loc("Dashboard")}
                    to="/"
                    icon={<HomeOutlinedIcon />}
                    setSelected={setSelected}
                    isMenuCollapsed={isMenuCollapsed}
                    selected={selected}
                  />
                )}
              {facilityTypeId === 1 && (
                <Item
                  title="Work Order"
                  to="/printWorkOrder"
                  icon={<ReceiptLongOutlinedIcon />}
                  setSelected={setSelected}
                  isMenuCollapsed={isMenuCollapsed}
                  selected={selected}
                />
              )}
              {facilityTypeId === 1 && (
                <Item
                  title="Verify Status"
                  to="/verifyStatus/PH"
                  icon={<LibraryAddCheckIcon />}
                  setSelected={setSelected}
                  isMenuCollapsed={isMenuCollapsed}
                  selected={selected}
                  permissions={allPermissions.VerifyPrintHouseSerial}
                />
              )}

              {facilityTypeId === 2 && (
                <Item
                  title="Inbound"
                  to="/aggregateInbound"
                  icon={<ArchiveOutlinedIcon />}
                  setSelected={setSelected}
                  isMenuCollapsed={isMenuCollapsed}
                  selected={selected}
                />
              )}
              {facilityTypeId === 2 && (
                <Item
                  title="Stack Aggregation"
                  to="/aggregateByStack"
                  icon={<LayersOutlinedIcon />}
                  setSelected={setSelected}
                  isMenuCollapsed={isMenuCollapsed}
                  selected={selected}
                  permissions={allPermissions.StackAggregation}
                />
              )}
              {facilityTypeId === 2 && (
                <Item
                  title="Stacks Manual Aggregation"
                  to="/stackAndCartonAggregation"
                  icon={<DynamicFeedOutlinedIcon />}
                  setSelected={setSelected}
                  isMenuCollapsed={isMenuCollapsed}
                  selected={selected}
                />
              )}
              {facilityTypeId === 2 && (
                <Item
                  title="Aggregation"
                  to="/aggregate"
                  icon={<AddToQueueOutlinedIcon />}
                  setSelected={setSelected}
                  isMenuCollapsed={isMenuCollapsed}
                  selected={selected}
                  permissions={allPermissions.AGG_Aggregate}
                />
              )}
              {facilityTypeId === 2 && (
                <Item
                  title="Disaggregation"
                  to="/aggregate/disaggregation"
                  icon={<RemoveFromQueueOutlinedIcon />}
                  setSelected={setSelected}
                  isMenuCollapsed={isMenuCollapsed}
                  selected={selected}
                />
              )}
              {facilityTypeId === 2 && (
                <Item
                  title="Outbound"
                  to="/aggregateOutbound"
                  icon={<UnarchiveOutlinedIcon />}
                  setSelected={setSelected}
                  isMenuCollapsed={isMenuCollapsed}
                  selected={selected}
                />
              )}
              {facilityTypeId === 2 && (
                <Item
                  title="WADs"
                  to="/aggregate/wads"
                  icon={<PlaylistAddCircleOutlinedIcon />}
                  setSelected={setSelected}
                  permissions={allPermissions.StackConfiguration}
                  selected={selected}
                />
              )}
              {facilityTypeId === 2 && (
                <Item
                  title="Verify Status"
                  to="/verifyStatus/AGG"
                  icon={<LibraryAddCheckIcon />}
                  setSelected={setSelected}
                  isMenuCollapsed={isMenuCollapsed}
                  selected={selected}
                  permissions={allPermissions.VerifyAggregationHouseSerial}
                />
              )}
              {facilityTypeId === 3 && (
                <Item
                  title="Inventory"
                  to="/acipInventory"
                  icon={<ApartmentOutlinedIcon />}
                  setSelected={setSelected}
                  isMenuCollapsed={isMenuCollapsed}
                  selected={selected}
                  permissions={allPermissions.INV_ViewReport}
                />
              )}
              {facilityTypeId === 3 && (
                <Item
                  title="Inbound"
                  to="/acipInbound"
                  icon={<ArchiveOutlinedIcon />}
                  setSelected={setSelected}
                  isMenuCollapsed={isMenuCollapsed}
                  selected={selected}
                  permissions={allPermissions.INOView}
                />
              )}
              {facilityTypeId === 3 && (
                <Item
                  title="Work Order"
                  to="/acipWorkOrder"
                  icon={<ReceiptLongOutlinedIcon />}
                  setSelected={setSelected}
                  isMenuCollapsed={isMenuCollapsed}
                  selected={selected}
                  permissions={allPermissions.CWOView}
                />
              )}
              {facilityTypeId === 3 && (
                <Item
                  title="Aggregation"
                  to="/aggregate"
                  icon={<AddToQueueOutlinedIcon />}
                  setSelected={setSelected}
                  isMenuCollapsed={isMenuCollapsed}
                  selected={selected}
                  permissions={allPermissions.AGG_Aggregate}
                />
              )}
              {facilityTypeId === 3 && (
                <Item
                  title="Pallet Disaggregation"
                  to="/acip/pallet-disaggregation"
                  icon={<RemoveFromQueueOutlinedIcon />}
                  setSelected={setSelected}
                  isMenuCollapsed={isMenuCollapsed}
                  selected={selected}
                  permissions={allPermissions.AGG_ACIPDisAggregate}
                />
              )}
              {facilityTypeId === 3 && (
                <Item
                  title="Outbound"
                  to="/acipOutbound"
                  icon={<UnarchiveOutlinedIcon />}
                  setSelected={setSelected}
                  isMenuCollapsed={isMenuCollapsed}
                  selected={selected}
                  permissions={allPermissions.OutOrderView}
                />
              )}
              {facilityTypeId === 3 && (
                <Item
                  title="CAPs"
                  to="/acip/allCaps"
                  icon={<HorizontalSplitOutlinedIcon />}
                  setSelected={setSelected}
                  isMenuCollapsed={isMenuCollapsed}
                  selected={selected}
                  permissions={allPermissions.CAPConfiguration}
                />
              )}
              {(facilityTypeId === 2 || facilityTypeId === 3) && (
                <Item
                  title="Printer Setup"
                  to="/printerSetup"
                  icon={
                    <RoomPreferencesOutlinedIcon sx={{ rotate: "270deg" }} />
                  }
                  setSelected={setSelected}
                  isMenuCollapsed={isMenuCollapsed}
                  selected={selected}
                  permissions={allPermissions.PrintingConfiguration}
                />
              )}
              {facilityTypeId === 3 && (
                <Item
                  title="Machine Configurations"
                  to="/machineConfigurations"
                  icon={<PermDataSettingIcon sx={{ rotate: "270deg" }} />}
                  setSelected={setSelected}
                  isMenuCollapsed={isMenuCollapsed}
                  selected={selected}
                  permissions={allPermissions.MachineViewing}
                />
              )}
              {facilityTypeId === 5 && (
                <Item
                  title="Dashboard"
                  to="/counterfeit/dashboard"
                  icon={<HomeOutlinedIcon />}
                  setSelected={setSelected}
                  isMenuCollapsed={isMenuCollapsed}
                  selected={selected}
                />
              )}
              {facilityTypeId === 5 && (
                <Item
                  title="Products Statistics"
                  to="/counterfeit/products-statistics"
                  icon={<AssessmentOutlinedIcon />}
                  setSelected={setSelected}
                  isMenuCollapsed={isMenuCollapsed}
                  selected={selected}
                />
              )}
              {facilityTypeId === 5 && (
                <Item
                  title="Alarms Settings"
                  to="/counterfeit/alarm-settings"
                  icon={<DoorbellOutlinedIcon />}
                  setSelected={setSelected}
                  isMenuCollapsed={isMenuCollapsed}
                  selected={selected}
                  permissions={allPermissions.AlarmViewing}
                />
              )}
              {facilityTypeId === 5 && (
                <Item
                  title="Message Configurations"
                  to="/counterfeit/configurations"
                  icon={<SettingsOutlinedIcon />}
                  setSelected={setSelected}
                  isMenuCollapsed={isMenuCollapsed}
                  selected={selected}
                  permissions={[
                    allPermissions.SMSViewing,
                    allPermissions.LandingPageViewing,
                    allPermissions.ShareAppViewing,
                  ]}
                  checkAnyValidPermission={true}
                />
              )}
              {facilityTypeId === 5 && (
                <Item
                  title="Message Approvals"
                  to="/counterfeit/edit-configurations"
                  icon={<Edit />}
                  setSelected={setSelected}
                  isMenuCollapsed={isMenuCollapsed}
                  selected={selected}
                  permissions={[
                    allPermissions.SMSApproval,
                    allPermissions.LandingPageApproval,
                    allPermissions.ShareAppApproval,
                  ]}
                  checkAnyValidPermission={true}
                />
              )}
              {facilityTypeId === 5 && (
                <Item
                  title="Reports"
                  to="/counterfeit/alarm-reports"
                  icon={<DescriptionSharpIcon />}
                  setSelected={setSelected}
                  isMenuCollapsed={isMenuCollapsed}
                  selected={selected}
                  permissions={allPermissions.ViewAllReports}
                />
              )}
              {facilityTypeId === 5 && (
                <Item
                  title="Repeated Serials"
                  to="/counterfeit/repeated-serials"
                  icon={<QrCodeScannerIcon />}
                  setSelected={setSelected}
                  isMenuCollapsed={isMenuCollapsed}
                  selected={selected}
                  permissions={allPermissions.RepeatedSerialsViewing}
                />
              )}
              {facilityTypeId === 6 && (
                <Item
                  title="Supply Chain Dashboard"
                  to="/supply-chain/dashboard"
                  icon={<HomeOutlinedIcon />}
                  setSelected={setSelected}
                  isMenuCollapsed={isMenuCollapsed}
                  selected={selected}
                />
              )}
              {facilityTypeId === 6 &&
                // add permission for LinkingFacilities
                (hasPermission(allPermissions.WADViewing) ||
                  hasPermission(allPermissions.CAPViewing) ||
                  hasPermission(allPermissions.EndProductViewing) ||
                  hasPermission(allPermissions.PrintHousesViewing) ||
                  hasPermission(allPermissions.AggregationHouseViewing) ||
                  hasPermission(allPermissions.CappingVendorsViewing) ||
                  hasPermission(allPermissions.LOBPViewing) ||
                  hasPermission(allPermissions.PartnerViewing)) && (
                  <SubMenu
                    title={!isMenuCollapsed ? "Master Data" : ""}
                    icon={<FactCheckOutlinedIcon />}
                    defaultOpen={location.pathname.startsWith("/master-data")}
                  >
                    <Item
                      title="WADs"
                      to="/master-data/wads"
                      icon={<PlaylistAddCircleOutlinedIcon />}
                      setSelected={setSelected}
                      isMenuCollapsed={isMenuCollapsed}
                      permissions={allPermissions.WADViewing}
                      selected={selected}
                    />
                    <Item
                      title="CAPs"
                      to="/master-data/caps"
                      icon={<HorizontalSplitOutlinedIcon />}
                      setSelected={setSelected}
                      isMenuCollapsed={isMenuCollapsed}
                      permissions={allPermissions.CAPViewing}
                      selected={selected}
                    />
                    <Item
                      title="Products"
                      to="/master-data/finished-products"
                      icon={<OilBarrelOutlinedIcon />}
                      setSelected={setSelected}
                      isMenuCollapsed={isMenuCollapsed}
                      permissions={allPermissions.EndProductViewing}
                      selected={selected}
                    />
                    <Item
                      title="Print House"
                      to="/master-data/print-house"
                      icon={<LocalPrintshopOutlinedIcon />}
                      setSelected={setSelected}
                      isMenuCollapsed={isMenuCollapsed}
                      permissions={allPermissions.PrintHousesViewing}
                      selected={selected}
                    />
                    <Item
                      title="Aggregation House"
                      to="/master-data/aggregation-house"
                      icon={<WarehouseOutlinedIcon />}
                      setSelected={setSelected}
                      isMenuCollapsed={isMenuCollapsed}
                      permissions={allPermissions.AggregationHouseViewing}
                      selected={selected}
                    />
                    <Item
                      title="CAP Vendor"
                      to="/master-data/cap-vendors"
                      icon={<ChairAltOutlinedIcon />}
                      setSelected={setSelected}
                      isMenuCollapsed={isMenuCollapsed}
                      permissions={allPermissions.CappingVendorsViewing}
                      selected={selected}
                    />
                    <Item
                      title="LOBP"
                      to="/master-data/lobp"
                      icon={<WaterDamageOutlinedIcon />}
                      setSelected={setSelected}
                      isMenuCollapsed={isMenuCollapsed}
                      permissions={allPermissions.LOBPViewing}
                      selected={selected}
                    />
                    <Item
                      title="Link Facilities"
                      to="/master-data/linkingFacilities"
                      icon={<HolidayVillageOutlinedIcon />}
                      setSelected={setSelected}
                      isMenuCollapsed={isMenuCollapsed}
                      permissions={allPermissions.PartnerViewing}
                      selected={selected}
                    />
                  </SubMenu>
                )}
              {facilityTypeId === 6 && (
                <Item
                  title="Product Verification"
                  to="/supply-chain/verifyProduct"
                  icon={<LibraryAddCheckIcon />}
                  setSelected={setSelected}
                  isMenuCollapsed={isMenuCollapsed}
                  selected={selected}
                  permissions={allPermissions.ProductVerification}
                />
              )}
              {facilityTypeId === 6 &&
                (hasPermission(allPermissions.ViewUsers) ||
                  hasPermission(allPermissions.RoleViewing)) && (
                  <SubMenu
                    title={!isMenuCollapsed ? "Access Management" : ""}
                    icon={<LockPersonOutlinedIcon />}
                    defaultOpen={location.pathname.startsWith(
                      "/access-management"
                    )}
                  >
                    <Item
                      title="Roles"
                      to="/access-management/roles"
                      icon={<ManageAccountsOutlinedIcon />}
                      setSelected={setSelected}
                      isMenuCollapsed={isMenuCollapsed}
                      permissions={allPermissions.RoleViewing}
                      selected={selected}
                    />
                    <Item
                      title="Users"
                      to="/access-management/users"
                      icon={<GroupOutlinedIcon />}
                      setSelected={setSelected}
                      isMenuCollapsed={isMenuCollapsed}
                      permissions={allPermissions.ViewUsers}
                      selected={selected}
                    />
                  </SubMenu>
                )}
              {facilityTypeId === 7 && (
                <Item
                  title="Loyalty Dashboard"
                  to="/loyalty/dashboard"
                  icon={<HomeOutlinedIcon />}
                  setSelected={setSelected}
                  isMenuCollapsed={isMenuCollapsed}
                  selected={selected}
                />
              )}
            </SidebarContent>
            <SidebarFooter>
              {!isMenuCollapsed ? (
                <Box className="p-2" display="flex" flexDirection="column">
                  <Typography sx={{ color: "#7F1332" }}>Powered By:</Typography>
                  <img
                    alt="Origin"
                    width="50%"
                    className="my-1"
                    style={{ alignSelf: "center" }}
                    src={`../../assets/svg/Origin_Primary.svg`}
                  />
                  <Typography
                    sx={{ color: "#89C384" }}
                    fontSize="0.7rem"
                  >{`Version: ${process.env.REACT_APP_VERSION}`}</Typography>
                </Box>
              ) : (
                <Box className="p-1" display="flex" flexDirection="column">
                  <Typography fontSize="0.7rem" sx={{ color: "#7F1332" }}>
                    Powered By:
                  </Typography>
                  <img
                    alt="Origin"
                    width="60%"
                    className="my-1"
                    style={{ alignSelf: "center" }}
                    src={`../../assets/svg/Origin_O.svg`}
                  />
                  <Typography
                    sx={{ color: "#89C384" }}
                    fontSize="0.6rem"
                  >{`Version: ${process.env.REACT_APP_VERSION}`}</Typography>
                </Box>
              )}
            </SidebarFooter>
          </Menu>
        </ProSidebar>
      </Box>
    </>
  );
};

export default Sidebar;
