import { axios_AUTH, simpleErrorHandling } from "config/axiosInstances";

const login = async (username, password) => {
  try {
    let body = {
      userName: username,
      password: password,
    };
    const response = await axios_AUTH.post(
      "/api/Authentication/GetToken",
      body
    );
    return response.data;
  } catch (err) {
    simpleErrorHandling(err, "Failed to get Token");
  }
};
const getNewAccessToken = async (refreshToken) => {
  try {
    const response = await axios_AUTH.post(
      "/api/Authentication/GetNewAccessToken",
      { refreshToken }
    );

    return response.data;
  } catch (err) {
    simpleErrorHandling(err, "Failed to get new token please login");
  }
};
const changePassword = async (body) => {
  try {
    const response = await axios_AUTH.post(
      "/api/Authentication/ChangePassword",
      body
    );
    return response.data;
  } catch (err) {
    simpleErrorHandling(err, "Failed to change password");
  }
};

export const authServices = {
  login,
  getNewAccessToken,
  changePassword,
};
